<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require(`@/assets/images/logo${$vuetify.theme.dark ? '' : '--dark'}.png`)"
              max-height="60px"
              alt="Perform72 logo"
              contain
              eager
              class="app-logo"
            ></v-img>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2 text-center">
            Admin Dashboard
          </p>
          <p class="mb-2 text-center">
            Please sign-in to your account
          </p>
        </v-card-text>

        <v-card-text>
          <ValidationObserver ref="formObserver">
            <v-form :disabled="isLoading" autocomplete="off" @submit.prevent="onSubmit">
              <vee-text-field
                v-model="formFields.email"
                label="Email"
                placeholder="john@example.com"
                hide-details
                class="mb-3"
              ></vee-text-field>

              <vee-text-field
                v-model="formFields.password"
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Password"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                hide-details
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></vee-text-field>

              <v-btn
                block
                color="primary"
                class="mt-6"
                :loading="isLoading"
                @click="onSubmit"
              >
                Login
              </v-btn>

              <p class="mt-6 text-center error--text">
                {{ formError }}
              </p>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>

    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >
  </div>
</template>

<script>
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { onMounted, ref, watch } from 'vue'
import { ValidationObserver } from 'vee-validate'
import { useRouter } from '@/utils/index'
import useAuth from '@/composables/useAuth'

export default {
  components: {
    ValidationObserver,
  },

  setup() {
    const formObserver = ref(null)
    const formError = ref('')

    const isLoading = ref(false)

    const isPasswordVisible = ref(false)
    const formFields = ref({
      email: '',
      password: '',
    })

    const { route, router } = useRouter()
    const { login, state: auth } = useAuth()

    onMounted(() => {
      if (auth.user) {
        const { query } = route.value
        const redirectTo = query.redirectTo && typeof query.redirectTo === 'string'
          ? query.redirectTo
          : '/'

        router.push(redirectTo)
      }

      watch(formFields, () => {
        formError.value = ''
      }, { deep: true })
    })

    const onSubmit = async () => {
      isLoading.value = true
      formError.value = ''

      try {
        const { user } = await login(formFields.value.email, formFields.value.password)

        auth.user = user
        auth.jwtData = await user.getIdTokenResult()
      } catch (error) {
        switch (error.code) {
          case 'auth/invalid-email':
            formError.value = 'Invalid email'
            break
          case 'auth/user-not-found':
            formError.value = 'No account with that email was found'
            break
          case 'auth/wrong-password':
            formError.value = 'Incorrect password'
            break
          default:
            formError.value = 'Email or password was incorrect'
        }

        isLoading.value = false
      }

      router.push({ name: 'dashboard' })
    }

    return {
      formObserver,
      formError,
      formFields,

      isLoading,
      isPasswordVisible,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      onSubmit,
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

  .app-logo {
    box-shadow: none;
    border-radius: 0;
  }
</style>
